@import 'src/app/_shared/_styles/mixins';
@import 'src/app/_shared/_styles/rs-variables/colors';

.rs-select {
  ::ng-deep {
    .mat-form-field.mat-focused .mat-select-arrow {
      transform: translateX(0) rotate(-135deg);
    }

    .mat-select-value-text {
      @include font(16px, 400, color-on_surface(70%));
    }

    .mat-form-field {
      @include size(100%, 44px);

      &:hover {
        ::ng-deep {
          .mat-form-field-outline {
            color: color-outline_focus() !important;
            border: 2px solid color-outline_focus();
            border-radius: 8px;
          }
        }
      }
    }

    .mat-form-field-outline {
      border: 1px solid color-outline();
      border-radius: 8px;
      height: 44px;
    }

    .mat-select {
      transform: translateY(-10px);
    }

    .mat-select-arrow-wrapper {
      transform: translateY(0);
      zoom: 1.2;
    }

    .mat-select-arrow {
      @include size(10px, 10px);

      border: none;
      transform: translate(0, -3px) rotate(45deg);
      border-right: 2px solid color-on_surface();
      border-bottom: 2px solid color-on_surface();
    }
  }
}
