@import 'src/app/_shared/_styles/mixins';
@import 'src/app/_shared/_styles/rs-variables/colors';
@import 'src/app/_shared/_styles/rs-variables/spacing';

.mat-slide-toggle {
  .mat-slide-toggle-label {
    .mat-slide-toggle-content {
      font-size: 16px;
      font-weight: 500;
      color: color-primary() !important;
    }

    .mat-slide-toggle-bar {
      @include size(52px !important, 32px !important);

      background-color: color-on_surface_variant() !important;
      border: 4px solid color-on_surface_variant() !important;
      border-radius: 100px !important;

      .mat-slide-toggle-thumb-container {
        @include size(26px !important, 26px !important);

        top: -1px !important;
        left: 0 !important;

        .mat-slide-toggle-thumb {
          @include size(26px !important, 26px !important);

          box-shadow: none !important;
          background-color: color-on_primary() !important;
        }
      }
    }
  }

  &.mat-checked:not(.mat-disabled) {
    .mat-slide-toggle-bar {
      background-color: color-primary() !important;
      border-color: color-primary() !important;

      .mat-slide-toggle-thumb-container {
        left: 2px !important;
      }
    }
  }
}
