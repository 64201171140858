@import 'src/app/_shared/_styles/mixins';
@import 'src/app/_shared/_styles/rs-variables/colors';
@import 'src/app/_shared/_styles/rs-variables/spacing';

$spacing-1: spacing(1);

rs-grid-item-label .rs-grid-item-label {
  @include font(16px, 400, color-on_surface());

  align-self: stretch;
  text-align: center;
  font-style: normal;
  line-height: 24px;
}

rs-grid-item-text .rs-grid-item-text {
  @include font(14px, 400, color-on_surface());

  align-self: stretch;
  text-align: center;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.035px;
}

rs-grid-item-icons .rs-grid-item--icons {
  @include flex_mix(row, center, center);

  gap: spacing(0.5);
}

rs-grid-item {
  button[rs-style='text'] {
    padding: $spacing-1 spacing(1.5);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    border-radius: $spacing-1;
    cursor: pointer;
    gap: $spacing-1;
    outline: 1px solid color-outline();
    box-shadow: 0 1px 1px 0 color-elevation-1(20%) !important;
    position: relative;

    rs-badge {
      position: absolute;
      top: -$spacing-1;
      right: -$spacing-1;
    }

    &.rs-button-grid-item {
      &--title-label {
        @include size(76px, auto);

        min-height: 72px;

        mat-icon {
          @include size(28px, 28px);
        }

        rs-grid-item-label .rs-grid-item-label {
          @include font(18px, 700, color-on_surface());

          letter-spacing: 0.036px;
        }
      }

      &--title-label-icons {
        min-width: 80px;
      }

      &--icon-label {
        @include size(76px, auto);

        min-height: 80px;
      }

      mat-icon {
        &,
        &:hover,
        &:focus {
          svg path {
            fill: color-on_surface();
          }
        }
      }

      &:active:not(:disabled),
      &--active:not(:disabled) {
        background-color: color-surface_select(8%);
        outline: 4px solid color-outline_select();

        mat-icon {
          &,
          &:hover,
          &:focus {
            svg path {
              fill: color-on_surface();
            }
          }
        }
      }

      &--overlay:not(:disabled) {
        outline: 1px solid color-information();
        box-shadow: 0 2px 10px 0 color-elevation-1(20%) !important;
      }
    }

    &:hover:not(:disabled) {
      background-color: color-surface_focus(12%);
    }

    &:focus:not(:disabled, .rs-button-grid-item--active) {
      outline: 4px solid color-outline_focus();
    }

    &.rs-button-grid-item--unavailable,
    &:disabled {
      &,
      &:hover,
      &:focus {
        background-color: color-on_surface(4%);
        outline: 1px solid color-outline(24%);

        mat-icon svg path {
          fill: color-on_surface(48%);
        }

        rs-grid-item-text .rs-grid-item-text,
        rs-grid-item-label .rs-grid-item-label {
          color: color-primary(48%);
        }
      }
    }

    &.rs-button-grid-item--unavailable {
      outline: 1px solid color-error();
    }
  }
}

rs-grid-item-icons mat-icon {
  @include size(24px, 24px);
}

[dir='rtl'] {
  rs-grid-item button[rs-style='text'] rs-badge {
    right: unset;
    left: -$spacing-1;
  }
}
