@import 'src/app/_shared/_styles/mixins';
@import 'src/app/_shared/_styles/rs-variables/colors';
@import 'src/app/_shared/_styles/rs-variables/spacing';

$spacing-0_75: spacing(0.75);
$spacing-1_25: spacing(1.25);
$spacing-2: spacing(2);
$spacing-2_25: spacing(2.25);
$spacing-2_5: spacing(2.5);
$spacing-3: spacing(3);

.rs-badge {
  @include flex_mix(row, center, center);
  @include font(12px, 700, color-on_primary());

  border-radius: 50%;
  background-color: color-brand();
  font-style: normal;
  text-align: center;

  mat-icon svg path {
    fill: color-on_primary();
    display: flex;
  }

  &--extra-small,
  &--dot {
    @include size($spacing-1_25, $spacing-1_25);
    @include font(10px, 300, color-on_primary());

    line-height: $spacing-1_25;

    mat-icon {
      @include size($spacing-0_75, $spacing-0_75);
    }
  }

  &--small {
    @include size($spacing-2_25, $spacing-2_25);
    @include font(12px, 700, color-on_primary());

    line-height: $spacing-2_25;
    letter-spacing: 0.048px;

    mat-icon {
      @include size($spacing-2, $spacing-2);
    }
  }

  &--medium {
    @include size($spacing-3, $spacing-3);
    @include font(14px, 500, color-on_primary());

    line-height: $spacing-3;
    letter-spacing: 0.028px;

    mat-icon {
      @include size($spacing-2_5, $spacing-2_5);
    }
  }
}

rs-badge-label {
  @include size(fit-content, 20px);

  .rs-badge-label {
    @include flex_mix(row, center, flex-start);
    @include font(14px, 700);
    @include size(fit-content, 20px);
    @include set-text-and-icon-color(color-on_surface_variant());

    font-style: normal;
    letter-spacing: 0.07px;
    gap: spacing(0.5);

    &:not(.rs-badge-label--uppercase) {
      text-transform: capitalize;
    }

    mat-icon {
      @include size(20px, 20px);
    }

    &--error {
      @include set-text-and-icon-color(color-error());
    }

    &--disabled {
      @include set-text-and-icon-color(color-on_surface(38%));
    }

    &--uppercase {
      text-transform: uppercase;
    }
  }
}

rs-badge-status {
  @include size(fit-content, fit-content);

  .rs-badge-status {
    @include size(fit-content, fit-content);
    @include flex_mix(row, center, flex-start);
    @include font(14px, 700);

    text-transform: uppercase;

    &--stadium,
    &--text {
      @include font(14px, 700);

      font-style: normal;
      letter-spacing: 0.07px;
      text-align: center;

      mat-icon {
        @include size(20px, 20px);
      }
    }

    &--stadium {
      @include set-text-and-icon-color(color-on_primary());

      gap: spacing(0.5);
      padding: spacing(0.25) spacing(1.5);
      background-color: var(--rs-badge-color, color-success());
      border-radius: 360px;
    }

    &--text {
      background-color: transparent;
      color: var(--rs-badge-color, color-warning());
    }

    &--round,
    &--flat {
      @include font(16px, 700, color-on_primary());

      height: 40px;
      min-width: 160px;
      padding: spacing(1.5) spacing(1);
      background-color: var(--rs-badge-color, color-warning());
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.08px;
    }

    &--round {
      border-radius: spacing(8);
    }
  }
}

rs-badge-legend {
  @include size(fit-content, fit-content);

  .rs-badge-legend {
    @include size(fit-content, fit-content);
    @include flex_mix(row, center, center);
    @include font(14px, 500);
    @include set-text-and-icon-color(var(--rs-badge-color, color-dining()));

    border-radius: spacing(1);
    border: 2px solid var(--rs-badge-color, color-dining());
    gap: spacing(0.5);
    padding: spacing(0.25) spacing(1.5);
    text-transform: uppercase;
    font-style: normal;
    letter-spacing: 0.028px;
    text-align: center;
    background-color: transparent;

    mat-icon {
      @include size(20px, 20px);
    }
  }
}
