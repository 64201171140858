@import 'src/app/_shared/_styles/mixins';
@import 'src/app/_shared/_styles/rs-variables/colors';

button {
  &[rs-chip='filter'] {
    @include font(14px, 500, color-on_surface());

    display: flex;
    font-style: normal;
    line-height: 20px;
    padding: 8px 12px;

    &.rs-chip-filter--revert-order {
      flex-direction: row-reverse;
    }

    mat-icon {
      svg path {
        fill: color-on_surface() !important;
      }

      &:not(.rs-chip-filter__leading-icon) {
        @include size(16px !important, 16px !important);

        line-height: 16px !important;
      }

      &.rs-chip-filter__leading-icon {
        @include size(24px !important, 24px !important);

        line-height: 24px !important;
      }
    }

    &[rs-chip-style='default'] {
      background-color: color-surface();
      border: 1px solid color-outline();
      letter-spacing: 0.028px;

      &:hover {
        background-color: color-surface_focus(12%);
      }

      &:focus {
        background-color: color-surface();
        border-color: color-outline_focus();
      }

      &.rs-chip-filter--selected,
      &[selected] {
        background-color: color-surface_select(12%);
        border-width: 4px;
        border-color: color-on_surface() !important;

        mat-icon svg path {
          fill: color-on_surface() !important;
        }
      }

      &[disabled] {
        background-color: color-surface();
        color: color-on_surface(38%) !important;
        border-color: color-on_surface(38%) !important;
        border-width: 1px;

        mat-icon svg path {
          fill: color-on_surface(38%) !important;
        }
      }
    }

    &[rs-chip-style='bold'] {
      background-color: color-surface_variant();
      border: 0;
      letter-spacing: 0.035px;

      &.rs-chip-filter--loading .rs-chip-filter__loading-icon {
        @include simple_animation(rotating, 2s, linear, infinite);
      }

      &:hover {
        background-color: color-surface_focus(12%);
      }

      &:focus {
        background-color: color-surface_focus(16%);
      }

      &.rs-chip-filter--selected,
      &[selected] {
        background-color: color-surface_select();
        color: color-on_surface_select() !important;

        mat-icon svg path {
          fill: color-on_surface_select() !important;
        }
      }

      &[disabled] {
        background-color: color-on_surface(16%);
        color: color-on_surface(38%) !important;

        mat-icon svg path {
          fill: color-on_surface(38%) !important;
        }
      }
    }
  }
}

@keyframes rotating {
  from {
    @include transform(rotate(0deg));
  }

  to {
    @include transform(rotate(360deg));
  }
}
