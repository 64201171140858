@import 'src/app/_shared/_styles/rs-variables/colors';

/* stylelint-disable */
@mixin dvh-height-if-browser-supports-it(
  $vhHeightWithoutUnits,
  $substractionWithUnits: 0
) {
  @if $substractionWithUnits == 0 {
    height: #{$vhHeightWithoutUnits}vh;

    @supports (height: #{$vhHeightWithoutUnits}dvh) {
      height: #{$vhHeightWithoutUnits}dvh;
    }
  } @else {
    height: calc(#{$vhHeightWithoutUnits}dvh - #{$substractionWithUnits});

    @supports (height: #{$vhHeightWithoutUnits}vh) {
      height: calc(#{$vhHeightWithoutUnits}vh - #{$substractionWithUnits});
    }
  }
}
/* stylelint-enable */

@mixin seo-friendly-hide() {
  height: 0;
  width: 0;
  overflow: hidden;
}

@mixin text-overflow-multi-line($height, $bgColor) {
  height: $height;
  position: relative;
  overflow: hidden;
  padding-right: 11px;
  word-break: break-all;

  &::before {
    position: absolute;
    content: '...';
    bottom: 0;
    right: 0;
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    width: 11px;
    height: 3rem;
    background: $bgColor;
  }
}

@mixin text-overflow() {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin flex_mix(
  $flex_direction: row,
  $align_items: center,
  $justify_content: center
) {
  display: flex;
  flex-direction: $flex_direction;
  align-items: $align_items;
  justify-content: $justify_content;
}

// generic transform
@mixin transform($transforms) {
  -ms-transform: $transforms;
  -moz-transform: $transforms;
  -webkit-transform: $transforms;
  -o-transform: $transforms;
  transform: $transforms;
}

// rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

// skew
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

@mixin transition($what: all, $time: 0.2s, $how: ease-in-out) {
  transition: $what $time $how;
}

@mixin flex($value) {
  -webkit-box-flex: $value;
  flex: $value;
}

@mixin flex-column-full-height() {
  display: flex;
  flex-direction: column;
  flex: 1;
}

@mixin flex-column-full-height-top-margin() {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 16px;
}

@mixin line-clamp($rows: 2) {
  display: box;
  -webkit-line-clamp: $rows;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin font($fontSize, $fontWeight, $fontColor: '') {
  font-size: $fontSize;
  font-weight: $fontWeight;

  @if $fontColor != '' {
    color: $fontColor;
  }
}

@mixin pseudo_divider($margin: 0) {
  content: '';
  display: block;
  border-radius: 8px;
  height: 3px;
  background: $text;
  margin: $margin;
}

@mixin simple_animation(
  $animationName,
  $duration,
  $timingFunction,
  $iterationCount
) {
  -webkit-animation: $animationName $duration $timingFunction $iterationCount;
  -moz-animation: $animationName $duration $timingFunction $iterationCount;
  -ms-animation: $animationName $duration $timingFunction $iterationCount;
  -o-animation: $animationName $duration $timingFunction $iterationCount;
  animation: $animationName $duration $timingFunction $iterationCount;
}

@mixin text-ellipsis($howMuchLines: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $howMuchLines;
  -webkit-box-orient: vertical;
}

@mixin set-text-and-icon-color($color: color-primary()) {
  color: $color;

  mat-icon svg path {
    fill: $color;
  }
}
