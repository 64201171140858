@import 'src/app/_shared/_styles/rs-variables/colors';
@import 'src/app/_shared/_styles/mixins';

.mat-radio-button {
  width: 100%;

  .mat-radio-container {
    @include size(22px, 22px);

    .mat-radio-outer-circle {
      @include size(100%, 100%);

      border-color: color-outline(100%);
    }
  }

  .mat-radio-ripple {
    @include size(44px !important, 44px !important);

    position: absolute !important;
    left: calc(50% - 22px) !important;
    top: calc(50% - 22px) !important;
    z-index: 1 !important;
    pointer-events: none !important;
  }

  .mat-radio-container:hover {
    .mat-radio-outer-circle::before {
      content: '';
    }

    .mat-radio-persistent-ripple {
      opacity: 1;
      background-color: color-surface_focus(12%) !important;
    }
  }

  &.mat-radio-checked {
    .mat-radio-outer-circle {
      background-color: color-primary() !important;
      border-color: color-primary() !important;

      &::before {
        @include font(16px, normal, color-on_primary());

        font-family: 'Material Icons', sans-serif;
        content: '\e5ca';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 200;
      }
    }

    .mat-radio-inner-circle {
      display: none;
    }

    .mat-radio-container:hover {
      .mat-radio-outer-circle {
        background-color: color-primary() !important;

        &::before {
          @include font(16px, normal, color-on_primary());

          font-family: 'Material Icons', sans-serif;
          content: '\e5ca';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 200;
        }
      }

      .mat-radio-persistent-ripple {
        opacity: 1;
        background-color: color-surface_focus(12%) !important;
      }
    }
  }

  &.mat-radio-disabled {
    .mat-radio-outer-circle {
      border-color: color-surface_select(16%);
    }

    .mat-radio-container:hover .mat-radio-persistent-ripple {
      opacity: 1;
      background-color: color-surface_select(12%) !important;
    }
  }

  &.mat-radio-checked.mat-radio-disabled {
    .mat-radio-outer-circle {
      background-color: color-on_surface(16%) !important;
      border: none !important;

      &::before {
        color: color-on_surface(38%);
      }
    }
  }

  &.mat-accent {
    .mat-radio-inner-circle,
    .mat-radio-button.mat-accent,
    &.mat-radio-checked .mat-radio-persistent-ripple,
    &:active .mat-radio-persistent-ripple {
      background-color: color-surface_focus(16%) !important;
    }
  }
}

.mat-radio-label {
  @include flex_mix(row, normal, space-between);
}
