@import 'src/app/_shared/_styles/mixins';
@import 'src/app/_shared/_styles/rs-variables/colors';
@import 'src/app/_shared/_styles/rs-variables/spacing';

$spacing-1: spacing(1);

.rs-list-item-segment {
  @include size(100%, 56px);
  @include flex_mix(row, center, flex-start);

  gap: $spacing-1;
  cursor: pointer;
  min-width: 304px;
  padding: $spacing-1 spacing(1.5);

  rs-list-item-segment-label * {
    @include font(16px, 400, color-on_surface_variant());

    font-style: normal;
    line-height: 24px;
  }

  rs-list-item-segment-hint *,
  rs-list-item-segment-action rs-list-item-segment-hint * {
    @include font(14px, 400, color-on_surface_variant());

    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.035px;
  }

  button {
    &,
    &:active,
    &:focus,
    &:disabled {
      background-color: transparent !important;
      color: inherit !important;
      width: 100%;
    }
  }

  // pressed => &:active
  // active => &--active
  // focused => &:focus
  // hovered => &:hover
  &:hover:not(:active, .rs-list-item-segment--disabled, .rs-list-item-segment--active),
  &:focus:not(:active, .rs-list-item-segment--disabled, .rs-list-item-segment--active) {
    color: color-on_surface();

    mat-icon svg path {
      fill: color-on_surface();
    }
  }

  &--active:not(.rs-list-item-segment--disabled),
  &:active:not(.rs-list-item-segment--disabled) {
    color: color-on_primary();

    mat-icon svg path {
      fill: color-on_primary();
    }

    rs-list-item-segment-label *,
    rs-list-item-segment-hint * {
      color: color-on_primary();
    }
  }

  &:hover:not(.rs-list-item-segment--disabled, .rs-list-item-segment--active) {
    background-color: color-surface_focus(12%);
  }

  &:focus:not(.rs-list-item-segment--disabled, .rs-list-item-segment--active) {
    background-color: color-surface_focus(16%);
  }

  &--active:not(.rs-list-item-segment--disabled) {
    background-color: color-brand();
  }

  &:active:not(.rs-list-item-segment--disabled, .rs-list-item-segment--active) {
    background-color: color-brand(84%);
  }

  &--disabled {
    color: color-on_surface(38%);

    mat-icon svg path {
      fill: color-on_surface(38%);
    }
  }

  rs-list-item-segment-action {
    display: flex;
    margin-left: auto;
    gap: $spacing-1;
  }
}
