@import 'src/app/_shared/_styles/mixins';
@import 'src/app/_shared/_styles/rs-variables/colors';
@import 'src/app/_shared/_styles/rs-variables/spacing';

.rs-card-horizontal {
  border-radius: 8px;
  border: 1px solid color-outline();
  background-color: color-on_primary();
  flex-direction: column;
  align-items: start;
  gap: 8px;
  padding: 12px;
  color: color-on_surface();
  text-decoration: none;

  &--disabled {
    cursor: not-allowed;
    pointer-events: none;
    background-color: color-surface_variant();
    color: color-on_surface(38%);

    rs-badge-status .rs-badge-status--text {
      color: color-on_surface(38%);
    }

    mat-icon svg path {
      fill: color-on_surface(38%) !important;
    }
  }

  &--active {
    outline: 4px solid color-outline_select();
  }

  & > * {
    display: flex;
    width: 100%;
  }
}

.rs-card-horizontal-line {
  @include flex_mix(row, normal, flex-start);
  @include font(14px, 400);

  letter-spacing: 0.035px;
  gap: spacing(1.5);

  &--bold {
    font-weight: 500;
  }

  span {
    @include text-overflow();
  }

  mat-icon {
    @include size(20px, 20px);

    font-size: 20px;
    min-width: 20px;
  }

  &--checkbox {
    display: flex;
    padding: 10px 12px;
    gap: 8px;

    span {
      overflow: visible;
    }

    .mat-checkbox {
      width: 100%;

      .mat-checkbox-layout {
        width: 100%;

        .mat-checkbox-label {
          overflow: hidden;
          width: 100%;
          text-align: left;
        }
      }
    }
  }
}

.rs-card-horizontal-title {
  @include flex_mix(row, center, flex-start);
  @include font(16px, 700);
  @include size(100%, 32px);

  line-height: 24px;
  gap: spacing(1.5);
  position: relative;

  span {
    @include text-overflow();

    text-align: left;
  }

  &--with-actions {
    span {
      width: fit-content;
      max-width: calc(100% - 290px);
    }
  }

  mat-icon {
    @include size(24px, 24px);

    min-width: 24px;
  }
}

.rs-card-horizontal-action {
  @include flex_mix(row, center, flex-end);
  @include font(16px, 700);
  @include text-overflow();

  gap: spacing(1.5);
  width: 100%;
  max-width: calc(100% - 100px);
  position: absolute;
  top: 0;
  right: 0;

  span {
    width: fit-content;
    max-width: calc(100% - 100px);
  }

  mat-icon {
    @include size(32px, 32px);

    min-width: 32px;
    border-radius: 8px;
    display: flex;
    border: 1px solid color-outline();
    background-color: color-on_primary();
    padding: 6px;
  }
}
