@import 'src/app/_shared/_styles/mixins';
@import 'src/app/_shared/_styles/rs-variables/colors';
@import 'src/app/_shared/_styles/rs-variables/spacing';

$spacing-0_75: spacing(0.75);
$spacing-1_5: spacing(1.5);

button:not([rs-shape='circle'], .rs-card-horizontal) {
  @include font(14px, 700, color-primary());

  cursor: pointer;
  box-sizing: border-box;
  line-height: 24px;
  font-style: normal;
  box-shadow: none !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 360px;
  gap: 8px;

  &.mat-button-disabled,
  &[disabled] {
    cursor: default;
  }

  mat-icon {
    line-height: 24px !important;
  }

  &:not([rs-style='dropdown-trigger'], [rs-style='none'], [rs-style='text-area']) {
    max-height: 40px;
  }

  &[rs-style='bold'],
  &[rs-style='outline'],
  &[rs-style='dropdown-trigger'] {
    padding: spacing(1.25) spacing(2.5);

    &:has(mat-icon:first-child) {
      padding-inline-start: spacing(1);
      padding-inline-end: spacing(2);
    }

    &:has(mat-icon:last-child) {
      padding-inline-start: spacing(2);
      padding-inline-end: spacing(1);
    }

    &:has(mat-icon:first-child):has(mat-icon:last-child) {
      padding-inline-start: spacing(1);
      padding-inline-end: spacing(1);
    }
  }

  &[rs-style='bold'],
  &[rs-style='outline'],
  &[rs-style='dropdown-trigger'],
  &[rs-style='text'] {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.08px;
  }

  &[rs-style='bold'] {
    color: color-on_primary();
    border: none;
    background: color-primary();

    mat-icon {
      color: color-on_primary();

      svg path {
        fill: color-on_primary();
      }
    }

    &:hover {
      background: color-primary(92%);
    }

    &:focus {
      background: color-primary(84%);
    }

    &.rs-button--active,
    &:active {
      background: color-primary(88%);
    }

    &.mat-button-disabled,
    &[disabled] {
      &,
      &:hover,
      &:focus,
      &.rs-button--active,
      &:active {
        background: color-primary(16%);
        color: color-on_surface(38%);

        mat-icon {
          color: color-on_surface(38%);

          svg path {
            fill: color-on_surface(38%);
          }
        }
      }
    }
  }

  &[rs-style='outline'],
  &[rs-style='dropdown-trigger'] {
    color: color-primary();

    &:hover {
      background: color-surface_focus(12%);
    }

    &:focus {
      outline: 1px solid color-outline_focus();
    }

    &.mat-button-disabled,
    &[disabled] {
      &,
      &:hover,
      &:focus,
      &.rs-button--active,
      &:active {
        color: color-primary(38%);
        outline: 1px solid color-on_surface(16%);

        mat-icon {
          color: color-on_surface(38%);

          svg path {
            fill: color-on_surface(38%);
          }
        }
      }
    }
  }

  &[rs-style='outline'] {
    background: color-surface();
    outline: 1px solid color-outline();
    border-color: transparent;

    &.rs-button--active,
    &:active {
      outline: 4px solid color-outline_select();
      background-color: color-surface_select(12%);
    }
  }

  &[rs-style='dropdown-trigger'] {
    background: color-surface();
    border: 1px solid color-outline();

    &.rs-button--active,
    &:active {
      outline: 1px solid color-outline_select();
      background-color: color-surface_select(12%);
    }
  }

  &[rs-style='text'] {
    padding: spacing(1) spacing(1.5);
    color: color-primary();
    border: none;

    &:has(mat-icon:first-child) {
      padding-inline-start: spacing(1);
      padding-inline-end: spacing(1.5);
    }

    &:has(mat-icon:last-child) {
      padding-inline-start: spacing(1.5);
      padding-inline-end: spacing(1);
    }

    &:has(mat-icon:first-child):has(mat-icon:last-child) {
      padding-inline-start: spacing(1);
      padding-inline-end: spacing(1);
    }

    &:not([rs-background='inherit']) {
      background: color-surface();
    }

    &[rs-background='inherit'] {
      background: inherit;
    }

    &:hover,
    &:focus {
      background-color: color-surface_focus(12%);
    }

    &.rs-button--active,
    &:active {
      background-color: color-surface_select();
      color: color-on_surface-variant-3();

      mat-icon {
        color: color-on_surface-variant-3();

        svg path {
          fill: color-on_surface-variant-3();
        }
      }
    }

    &.mat-button-disabled,
    &[disabled] {
      &,
      &:hover,
      &:focus,
      &.rs-button--active,
      &:active {
        color: color-primary(38%);
        background: color-surface_select(12%);

        mat-icon {
          color: color-on_surface(16%);

          svg path {
            fill: color-on_surface(16%);
          }
        }
      }
    }
  }

  &[rs-style='link'] {
    @include font(14px, 400, color-primary());

    text-decoration: underline;
    padding: 0 20px;
    color: color-primary();
    background: color-surface();
    border: none;

    &:hover,
    &:focus {
      background-color: color-surface_focus(12%);
    }
  }

  &[rs-style='none'] {
    background-color: transparent;
    border-radius: 0;
    border: none;
    outline: none;
    justify-content: flex-start;
    padding: 0;
  }
}

button[rs-shape='circle'] {
  @include size(32px, 32px);
  @include flex_mix(row, center, center);

  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: none !important;
  padding: 0;

  &[disabled] {
    cursor: default;
  }

  mat-icon {
    @include size(20px, 20px);
    @include flex_mix(row, center, center);

    max-width: 20px;
    max-height: 20px;
  }

  &[rs-style='standart'] {
    background: color-on_primary();
    border: none;

    mat-icon {
      color: color-primary();

      svg path {
        fill: color-primary();
      }
    }

    &:hover {
      background: color-surface_focus(12%);
    }

    &:focus,
    &.rs-button--active,
    &:active {
      background: color-surface_focus(16%);
    }

    &[disabled] {
      &,
      &:hover,
      &:focus,
      &.rs-button--active,
      &:active {
        mat-icon {
          color: color-on_surface(38%);

          svg path {
            fill: color-on_surface(38%);
          }
        }
      }
    }
  }

  &[rs-style='primary'] {
    background: color-primary();
    border: none;

    mat-icon {
      color: color-on_primary();

      svg path {
        fill: color-on_primary();
      }
    }

    &:hover {
      background: color-primary(92%);
    }

    &:focus {
      background: color-primary(88%);
    }

    &.rs-button--active,
    &:active {
      background: color-primary(84%);
    }

    &[disabled] {
      &,
      &:hover,
      &:focus,
      &.rs-button--active,
      &:active {
        mat-icon {
          color: color-on_surface(38%);

          svg path {
            fill: color-on_surface(38%);
          }
        }
      }
    }
  }

  &[rs-style='outline'] {
    background: color-on_primary();
    border: none;
    outline: 1px solid color-outline();

    mat-icon {
      color: color-primary();

      svg path {
        fill: color-primary();
      }
    }

    &:hover {
      background: color-surface_focus(12%);
    }

    &:focus {
      outline: 1px solid color-outline_focus();
    }

    &.rs-button--active,
    &:active {
      outline: 2px solid color-primary();
      background: color-surface_select(8%);
    }

    &[disabled] {
      &,
      &:hover,
      &:focus,
      &.rs-button--active,
      &:active {
        outline: 1px solid color-primary(38%);

        mat-icon {
          color: color-on_surface(38%);

          svg path {
            fill: color-on_surface(38%);
          }
        }
      }
    }
  }
}

rs-button-segmented-wrapper {
  display: flex;

  button[rs-style='segmented'] {
    height: 40px;
    min-width: 44px;
    min-height: 36px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: color-primary();
    outline: 1px solid color-outline();
    background: color-surface();
    padding: $spacing-0_75 $spacing-1_5;
    border-color: transparent;
    cursor: pointer;

    &,
    & > span,
    & > p {
      @include font(16px, 500, color-on_surface());

      font-style: normal;
      line-height: 24px;
      text-transform: capitalize;
      text-align: center;
    }

    &:nth-child(1):not(:nth-last-child(1)) {
      border-end-end-radius: 0;
      border-start-end-radius: 0;
    }

    &:nth-last-child(1):not(:nth-child(1)) {
      border-end-start-radius: 0;
      border-start-start-radius: 0;
    }

    &:not(:nth-last-child(1), :nth-child(1)) {
      border-radius: 0;
    }

    mat-icon {
      color: color-on_surface();

      svg path {
        fill: color-on_surface();
      }
    }

    &:hover:not(:disabled) {
      background: color-surface_focus(12%);
    }

    &:focus:not(:disabled) {
      background: color-surface_focus(16%);
    }

    &.rs-button--active:not(:disabled),
    &:active:not(:disabled) {
      background: color-primary(88%);
      outline-color: color-surface_select();
      color: color-surface();

      mat-icon {
        color: color-surface();

        svg path {
          fill: color-surface();
        }
      }
    }

    &:disabled {
      color: color-on_surface(38%);

      mat-icon {
        color: color-on_surface(38%);

        svg path {
          fill: color-on_surface(38%);
        }
      }
    }
  }
}

/* stylelint-disable */
a[rs-style^='button'] {
  @include font(16px, 700, color-primary());

  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  line-height: 24px;
  font-style: normal;
  box-shadow: none !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 360px;
  gap: 8px;

  &.mat-button-disabled,
  &[disabled] {
    cursor: default;
  }

  mat-icon {
    line-height: 24px !important;
  }

  &[rs-style$='bold'],
  &[rs-style$='outline'] {
    padding: 10px 20px;
  }

  &[rs-style$='bold'],
  &[rs-style$='outline'],
  &[rs-style$='outline'] {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.08px;
  }

  &[rs-style$='bold'] {
    color: color-on_primary();
    border: none;
    background: color-primary();

    mat-icon {
      color: color-on_primary();

      svg path {
        fill: color-on_primary();
      }
    }

    &:hover {
      background: color-primary(92%);
    }

    &:focus {
      background: color-primary(84%);
    }

    &.rs-button--active,
    &:active {
      background: color-primary(88%);
    }

    &.mat-button-disabled,
    &[disabled] {
      &,
      &:hover,
      &:focus,
      &.rs-button--active,
      &:active {
        background: color-primary(16%);
        color: color-on_surface(38%);

        mat-icon {
          color: color-on_surface(38%);

          svg path {
            fill: color-on_surface(38%);
          }
        }
      }
    }
  }

  &[rs-style$='outline'] {
    color: color-primary();
    outline: 1px solid color-outline();
    background: color-surface();

    &:hover {
      background: color-surface_focus(12%);
    }

    &:focus {
      outline: 1px solid color-outline_focus();
    }

    &.rs-button--active,
    &:active {
      outline: 4px solid color-outline_select();
      background-color: color-surface_select(12%);
    }

    &.mat-button-disabled,
    &[disabled] {
      &,
      &:hover,
      &:focus,
      &.rs-button--active,
      &:active {
        color: color-primary(38%);
        outline: 1px solid color-on_surface(16%);

        mat-icon {
          color: color-on_surface(38%);

          svg path {
            fill: color-on_surface(38%);
          }
        }
      }
    }
  }

  &[rs-style$='outline'] {
    padding: 8px 4px;
    color: color-primary();
    border: none;

    &:not([rs-background='inherit']) {
      background: color-surface();
    }

    &[rs-background='inherit'] {
      background: inherit;
    }

    &:hover,
    &:focus {
      background-color: color-surface_focus(12%);
    }

    &.rs-button--active,
    &:active {
      background-color: color-surface_select();
      color: color-on_surface-variant-3();

      mat-icon {
        color: color-on_surface-variant-3();

        svg path {
          fill: color-on_surface-variant-3();
        }
      }
    }

    &.mat-button-disabled,
    &[disabled] {
      &,
      &:hover,
      &:focus,
      &.rs-button--active,
      &:active {
        color: color-primary(38%);
        background: color-surface_select(12%);

        mat-icon {
          color: color-on_surface(16%);

          svg path {
            fill: color-on_surface(16%);
          }
        }
      }
    }
  }

  &[rs-style$='link'] {
    @include font(14px, 400, color-primary());

    text-decoration: underline;
    padding: 0 20px;
    color: color-primary();
    background: color-surface();
    border: none;

    &:hover,
    &:focus {
      background-color: color-surface_focus(12%);
    }
  }

  &[rs-style$='none'] {
    background-color: transparent;
    border-radius: 0;
    border: none;
    outline: none;
    justify-content: flex-start;
    padding: 0;
  }

  &[rs-x-paddings='no'] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
/* stylelint-enable */
