@import 'src/app/_shared/_styles/mixins';
@import 'src/app/_shared/_styles/rs-variables/colors';
@import 'src/app/_shared/_styles/rs-variables/spacing';

$spacing-05: spacing(0.5);
$spacing-1: spacing(1);
$spacing-2: spacing(2);

.rs-banner-alert {
  display: flex;
  align-items: center;

  &:not(.rs-banner-alert--big) {
    gap: $spacing-1;
  }

  &--rounded {
    border-radius: 60px;
    padding: 6px 12px 6px $spacing-1;
  }

  &--default {
    padding: $spacing-05 $spacing-05 $spacing-05 12px;
  }

  &--big {
    padding: $spacing-2;
    align-items: center;
    justify-content: center;
  }

  &--information {
    background-color: color-information_container();
    color: color-on_information_container();
  }

  &--warning {
    background-color: color-warning_container();
    color: color-on_warning_container();
  }

  &--error {
    background-color: color-error_container();
    color: color-on_error_container();
  }

  &--success {
    background-color: color-success_container();
    color: color-on_success_container();
  }

  &__status-icon,
  &__close-icon {
    @include size(24px !important, 24px !important);

    display: flex !important;
  }

  &__close-icon {
    cursor: pointer;

    &--information svg path {
      fill: color-on_information_container() !important;
    }

    &--warning svg path {
      fill: color-on_warning_container() !important;
    }

    &--error svg path {
      fill: color-on_error_container() !important;
    }

    &--success svg path {
      fill: color-on_success_container() !important;
    }
  }

  &__content {
    display: flex;
    flex: 1;
    gap: $spacing-1;

    &-wrapper--big {
      flex-direction: column;
    }

    &--rounded,
    &--default,
    &--big {
      font-style: normal;
      font-weight: 500;
    }

    &--rounded {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.028px;
    }

    &--default {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.032px;
    }

    &--big {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.048px;

      &,
      &-supporting-text {
        text-align: center;
      }

      &-supporting-text {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
