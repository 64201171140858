@import 'src/app/_shared/_styles/mixins';
@import 'src/app/_shared/_styles/rs-variables/colors';
@import 'src/app/_shared/_styles/rs-variables/spacing';

rs-drawer-title,
rs-drawer-section-title,
rs-drawer-item .rs-drawer-item {
  padding: spacing(2);
}

rs-drawer-title,
rs-drawer-section-title {
  display: flex;
}

rs-drawer-title .rs-drawer-title {
  @include size(100%, 24px);
  @include font(18px, 700, color-on_surface());

  align-self: stretch;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.036px;
  margin: 0;
}

rs-drawer-section-title .rs-drawer-section-title {
  @include size(100%, 24px);
  @include font(14px, 500, color-on_surface());

  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.028px;
  margin: 0;
}

rs-drawer-item .rs-drawer-item {
  @include size(100%, 56px);
  @include flex_mix(row, center, flex-start);

  gap: spacing(1);
  cursor: pointer;
  min-width: 304px;

  // pressed => &:active
  // active => &--active
  // focused => &:focus
  // hovered => &:hover
  &--active,
  &:hover,
  &:focus,
  &:active {
    border-radius: 360px;
  }

  &:hover:not(:active, .rs-drawer-item--disabled, .rs-drawer-item--active),
  &:focus:not(:active, .rs-drawer-item--disabled, .rs-drawer-item--active) {
    color: color-on_surface();

    mat-icon svg path {
      fill: color-on_surface();
    }
  }

  &--active:not(.rs-drawer-item--disabled),
  &:active:not(.rs-drawer-item--disabled) {
    color: color-on_primary();

    mat-icon svg path {
      fill: color-on_primary();
    }
  }

  &:hover:not(.rs-drawer-item--disabled, .rs-drawer-item--active) {
    background-color: color-surface_focus(12%);
  }

  &:focus:not(.rs-drawer-item--disabled, .rs-drawer-item--active) {
    background-color: color-surface_focus(16%);
  }

  &--active:not(.rs-drawer-item--disabled) {
    background-color: color-brand();
  }

  &:active:not(.rs-drawer-item--disabled, .rs-drawer-item--active) {
    background-color: color-brand(84%);
  }

  &--disabled {
    color: color-on_surface(38%);

    mat-icon svg path {
      fill: color-on_surface(38%);
    }
  }

  rs-badge {
    margin-left: auto;
  }
}

rs-drawer-list .rs-drawer-list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-inline: 0;
  list-style-type: none;
  padding: spacing(1) 0;

  &--with-separator {
    padding-bottom: spacing(1.5);
    border-bottom: 1px solid color-outline();
  }
}

rs-drawer {
  background-color: color-on_primary();

  .rs-drawer {
    padding: 0 spacing(1.5);
  }
}
