@import 'src/app/_shared/_styles/mixins';
@import 'src/app/_shared/_styles/rs-variables/colors';
@import 'src/app/_shared/_styles/rs-variables/spacing';

$spacing-0_5: spacing(0.5);
$spacing-1: spacing(1);

.rs-tooltip.mat-menu-panel {
  padding: $spacing-0_5 $spacing-1 $spacing-0_5 $spacing-1;
  min-width: fit-content;
  max-width: 328px;
  min-height: fit-content;
  background-color: color-secondary();

  .rs-tooltip__tooltip-content {
    &,
    & > * {
      @include font(12px, 400, color-on_secondary());

      font-style: normal;
      line-height: 18px;
      letter-spacing: 0.048px;
    }

    &-header {
      &,
      & > * {
        @include font(14px, 500, color-on_secondary());

        font-style: normal;
        line-height: 20px;
        letter-spacing: 0.035px;
      }
    }
  }
}
