@import 'src/app/_shared/_styles/mixins';
@import 'src/app/_shared/_styles/rs-variables/colors';
@import 'src/app/_shared/_styles/rs-variables/spacing';

$spacing-1: spacing(1);
$spacing-1_5: spacing(1.5);
$spacing-2: spacing(2);
$spacing-3: spacing(3);

mat-dialog-container.mat-dialog-container {
  padding: 0 !important;
  border-radius: 0 !important;
}

rs-dialog,
rs-full-height-dialog {
  background-color: color-surface();

  .rs-tooltip__tooltip-content--on-dialog {
    &,
    & > * {
      @include font(unset, unset, unset);

      line-height: unset;
      letter-spacing: unset;
      background-color: unset;
    }
  }

  .rs-status-dialog-header {
    @include font(24px, 500, color-on_surface());

    font-style: normal;
    line-height: 32px;
    letter-spacing: 0.048px;
    gap: $spacing-1;

    &__details {
      @include font(16px, 500, color-on_surface());

      padding: $spacing-1 $spacing-3 $spacing-1 $spacing-3;
      background-color: color-surface_variant();
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.032px;
    }

    mat-icon {
      @include size(32px !important, 32px !important);

      display: flex;
      cursor: pointer;
    }

    &--error {
      background-color: color-warning_container();
      color: color-on_warning_container();

      // TODO change when we have suitable icon
      mat-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: color-on_warning_container();
        border-radius: 50%;

        svg {
          @include size(20px !important, 20px !important);

          path {
            fill: color-on_warning();
          }
        }
      }
      // TODO end
    }

    &--success {
      background-color: color-success_container();
      color: color-on_success_container();
    }
  }

  .rs-dialog-actions__actions {
    padding: $spacing-3 $spacing-3 0 $spacing-3;

    &--row {
      gap: 16px;
    }

    &--column {
      gap: 8px;

      button[rs-style='text'] {
        max-width: fit-content !important;
      }
    }

    button {
      text-transform: uppercase;
    }
  }
}

rs-dialog {
  .rs-status-dialog-header {
    padding: $spacing-3;
  }

  .rs-dialog-header {
    &--with-bottom-border {
      border-bottom: 1px solid color-surface_variant();
    }

    &--default {
      padding: $spacing-3 $spacing-3 0 $spacing-3;

      .rs-dialog-header__close-icon-wrapper mat-icon {
        @include size(20px !important, 20px !important);

        color: color-on_surface();
        cursor: pointer;

        svg path {
          fill: color-on_surface();
        }
      }

      &.rs-dialog-header--only-text-and-close-button {
        display: flex;
        flex-direction: row-reverse;
        gap: 8px;

        .rs-dialog-header__title {
          flex: 1;
        }
      }

      .rs-dialog-header__title {
        @include font(20px, 500, color-on_surface());

        font-style: normal;
        line-height: 28px;
        gap: 8px;

        &--after-close-icon {
          padding-top: $spacing-3;
        }

        &--has-no-icon {
          padding-top: 0;
        }

        mat-icon {
          @include size(20px !important, 20px !important);

          min-width: 20px;
          color: color-on_surface();
          cursor: pointer;

          svg path {
            fill: color-on_surface();
          }
        }
      }
    }

    &--success {
      .rs-dialog-header-status {
        color: color-on_success_container();
        background-color: color-success_container();
      }
    }
  }

  rs-default-dialog-header {
    margin: unset !important;
    font: unset !important;
    letter-spacing: unset !important;
  }

  rs-dialog-actions {
    // this styles to reset material styles
    // which brokes dialog ui
    padding: unset !important;
    margin-bottom: unset !important;
    display: inline !important;
  }

  .rs-dialog__body {
    display: block;
    padding: $spacing-3 $spacing-3 0 $spacing-3;
    margin: 0 auto;

    &--without-buttons {
      padding-bottom: $spacing-3;
    }
  }

  .rs-dialog-actions__actions {
    padding-bottom: $spacing-2;
  }
}

rs-full-height-dialog {
  .rs-dialog-header {
    padding: $spacing-1 $spacing-1_5 $spacing-1 $spacing-1_5;
    min-height: 56px;

    &--with-bottom-border {
      border-bottom: 1px solid color-surface_variant();
    }

    &--success,
    &--error {
      padding: $spacing-1_5 $spacing-1_5 $spacing-1_5 $spacing-1;
    }

    &--success {
      background-color: color-success_container();
    }

    &--error {
      background-color: color-warning_container();
    }

    .rs-dialog-header__title {
      @include font(18px, 500, color-on_surface());

      font-style: normal;
      line-height: 24px;
      gap: 6px;
      height: 40px;

      & > * {
        &:nth-child(2) {
          flex: 1;
        }

        &:nth-child(3),
        &:nth-child(4) {
          margin-left: 8px;
        }
      }

      mat-icon {
        @include size(20px !important, 20px !important);

        display: flex;
        color: color-on_surface();
        cursor: pointer;

        svg path {
          fill: color-on_surface();
        }
      }
    }
  }

  .rs-status-dialog-header {
    padding: $spacing-2;
  }

  .rs-dialog__body {
    padding: $spacing-2 $spacing-1_5 $spacing-2 $spacing-1_5;
    min-height: 120px;
    height: calc(100vh - 76px);
    max-width: 1400px;
    margin: 0 auto;

    &--actions {
      min-height: calc(100vh - 76px);
      height: fit-content;
    }
  }

  @supports (height: 100dvh) {
    .rs-dialog__body {
      height: calc(100dvh - 76px);

      &--actions {
        min-height: calc(100dvh - 76px);
      }
    }
  }

  .rs-dialog-actions__actions {
    padding-bottom: $spacing-2;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: color-on_primary(30%);
    z-index: 2;
  }

  rs-dialog-actions {
    display: flex;
    height: 107px;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
