@import 'src/app/_shared/_styles/mixins';
@import 'src/app/_shared/_styles/rs-variables/colors';

button {
  &[rs-chip='suggestion'] {
    border-radius: 64px;
    background: color-surface();
    box-shadow: none !important;
    color: color-on_surface() !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.028px;
    padding: 8px 12px;
    border: 1px solid color-on_surface();

    mat-icon {
      &:not(.rs-suggestion-chip__action) {
        @include size(24px !important, 24px !important);

        &:not(.rs-suggestion-chip__icon--save-color) svg path {
          fill: color-on_surface();
        }
      }

      &.rs-suggestion-chip__action {
        @include size(16px !important, 16px !important);

        line-height: 16px !important;
      }
    }

    &:hover {
      border: 1px solid color-outline();
      background: color-surface_focus(12%);
    }

    &:focus {
      border: 1px solid color-outline_focus();
      background: color-surface(12%);
    }

    &.rs-suggestion-chip--selected,
    &:active {
      &,
      &:hover {
        border: 4px solid color-on_surface() !important;
        background: color-surface() !important;
      }
    }

    &.rs-suggestion-chip--disabled,
    &[disabled] {
      border: 1px solid color-on_surface(38%) !important;
      background: color-surface() !important;
      color: color-on_surface(38%) !important;

      mat-icon {
        &:not(.rs-suggestion-chip__action) {
          color: color-on_surface(38%) !important;

          &:not(.rs-suggestion-chip__icon--save-color) svg path {
            fill: color-on_surface(38%);
          }

          &.rs-suggestion-chip__icon--save-color svg path {
            opacity: 0.38;
          }
        }

        &.rs-suggestion-chip__action {
          &:not(.rs-suggestion-chip__icon--save-color) svg path {
            fill: color-on_surface(38%);
          }
        }
      }
    }
  }
}
