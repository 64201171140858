@import 'src/app/_shared/_styles/mixins';
@import 'src/app/_shared/_styles/rs-variables/colors';
@import 'src/app/_shared/_styles/rs-variables/spacing';

$spacing-1: spacing(1);
$spacing-0_5: spacing(0.5);

.cdk-overlay-container {
  .cdk-overlay-connected-position-bounding-box > .cdk-overlay-pane {
    &.rs-override-autocomplete {
      transform: translateY(-4px);
      border-top: none !important;
      box-shadow: 0 1px 1px 0 color-elevation-1(20%);
      border-radius: 0 0 $spacing-1 $spacing-1;
      border-style: solid;
      background-color: color-on_surface-variant-3();

      &.mat-autocomplete-panel-above {
        bottom: unset !important;
      }

      &.rs-override {
        &-default {
          border-color: color-outline();
          border-width: 1px;
        }

        &-focused {
          border-color: color-outline_focus();
          border-width: 3.4px;
          min-width: 161px;
          box-sizing: border-box;

          > .mat-autocomplete-panel {
            border: 1px solid color-outline();
          }
        }
      }

      > .mat-autocomplete-panel {
        border-top: none !important;
      }

      .mat-option {
        color: color-on_surface() !important;
      }
    }
  }
}
