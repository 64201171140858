@import 'src/app/_shared/_styles/rs-variables/colors';
@import 'src/app/_shared/_styles/mixins';

.mat-checkbox {
  .mat-checkbox-inner-container {
    @include size(22px, 22px);

    &:not(.mat-checkbox-inner-container-no-side-margin) {
      margin-right: 10px; // TODO fix it would be incorrect in case rtl
    }

    &:hover .mat-checkbox-persistent-ripple {
      background-color: color-primary(8%) !important;
      opacity: 1;
    }
  }

  .mat-checkbox-ripple {
    @include size(44px !important, 44px !important);

    position: absolute !important;
    left: calc(50% - 22px) !important;
    top: calc(50% - 22px) !important;
    z-index: 1 !important;
    pointer-events: none !important;
  }

  .mat-checkbox-frame {
    border-radius: 4px;
    border: 1px solid color-outline(100%);
  }

  .mat-checkbox-layout {
    width: 100%;

    .mat-checkbox-label {
      flex: 1;
    }
  }

  &-checked {
    .mat-checkbox-background {
      background-color: color-primary(100%) !important;
      border-radius: 4px;
      background-image: url('../../../../assets/icons/common/icon.outline.checkmark.svg');
      background-size: 18px;
      background-repeat: no-repeat;
      background-position: center;
    }

    .mat-checkbox-checkmark {
      .mat-checkbox-checkmark-path {
        stroke: none !important;
      }
    }
  }

  &-disabled {
    .mat-checkbox-frame {
      border: none !important;

      &:not(.mat-checkbox-checked) {
        border: 1px solid color-on_surface(16%) !important;
      }

      .mat-checkbox-checked {
        border: none !important;
      }
    }

    .mat-checkbox-checkmark-path {
      stroke: color-on_surface(38%) !important;
    }

    &.mat-checkbox-checked .mat-checkbox-background {
      background-color: color-on_surface(16%) !important;
    }
  }
}

.mat-checkbox-checked.mat-checkbox-disabled {
  .mat-checkbox-frame {
    border: none !important;
  }
}
