@use '@angular/material' as mat;

@import 'src/app/_shared/_styles/colors';
@import 'src/app/_shared/_styles/responsive.mixin';
@import '/node_modules/angular-notifier/styles.scss';
@import 'src/app/_shared/_styles/mixins';
@import 'src/app/_shared/_styles/rs-design/rs-design';
@import 'src/app/_shared/_styles/rs-variables/spacing';
@import 'src/app/_shared/_styles/rs-variables/food-menu-grid';

@include mat.core();

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;

  --primary: transparent;
  --secondary: transparent;
}

$primary-color: (
  100: var(--common-primary),
  500: var(--common-primary),
  700: var(--common-primary),
  contrast: (
    100: #fff,
    500: #fff,
    700: #fff,
  ),
);

$accent-color: (
  100: #4a4a4a,
  500: #4a4a4a,
  700: #4a4a4a,
  contrast: (
    100: #fff,
    500: #fff,
    700: #fff,
  ),
);

$warn-color: (
  100: #fb1d09,
  500: #fb1d09,
  700: #fb1d09,
  contrast: (
    100: #fff,
    500: #fff,
    700: #fff,
  ),
);

$rs-web-custom-renderer-primary: mat.define-palette($primary-color);
$rs-web-custom-renderer-accent: mat.define-palette($accent-color);
$rs-web-custom-renderer-warn: mat.define-palette($warn-color);

$rs-web-custom-renderer-theme: mat.define-light-theme(
  (
    color: (
      primary: $rs-web-custom-renderer-primary,
      accent: $rs-web-custom-renderer-accent,
      warn: $rs-web-custom-renderer-warn,
    ),
  )
);

$aos-distance: 300px; // It has to be above import

@import 'node_modules/aos/src/sass/aos.scss';

@include mat.all-component-themes($rs-web-custom-renderer-theme);

$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 860px,
  lg: 1240px,
  xl: 1305px,
);

@import '~bootstrap/scss/bootstrap-grid.scss';
@import '~bootstrap/scss/bootstrap-utilities.scss';
@import '~mapbox-gl/dist/mapbox-gl.css';

.ltr {
  direction: ltr;
}

.rs-spacer {
  flex: 1 1 auto;
}

mat-dialog-container.mat-dialog-container {
  border-radius: 8px !important;
}

.rs-full-height--dvh {
  height: 100vh;

  @supports (height: 100dvh) {
    height: 100dvh;
  }
}

.rs-max-full-height--dvh {
  max-height: 100vh;

  @supports (max-height: 100dvh) {
    max-height: 100dvh;
  }
}

.rs-full-screen-modal .mat-dialog-container {
  width: 100%;
  height: 100%;
}

.rs-full-screen-modal-with-two-scrollable-parts .mat-dialog-container {
  width: 100%;
  max-height: 100vh;
  overflow: hidden;

  @supports (max-height: 100dvh) {
    max-height: 100dvh;
  }
}

.rs-dine-in-cart-full-screen-modal .mat-dialog-container {
  padding: 0 !important;
}

.rs-popup,
.rs-payment-cards-popup {
  min-width: 500px !important;

  .mat-dialog-container {
    padding: 0;
  }

  @media screen and (max-width: 576px) {
    min-width: 90% !important;
    max-width: 90% !important;
  }
}

.rs-payment-cards-popup {
  max-height: 63vh;
}

.rs-bottom-sheet--show-header {
  &,
  & mat-bottom-sheet-container {
    max-height: calc(100vh - 56px);
    height: calc(100vh - 56px);

    @supports (height: 100dvh) {
      max-height: calc(100dvh - 56px);
      height: calc(100dvh - 56px);
    }
  }
}

.rs-schedule-bottom-sheet mat-bottom-sheet-container {
  padding: 0;
}

::ng-deep {
  .mat-button-toggle-group-appearance-standard {
    .mat-button-toggle + .mat-button-toggle {
      border: 0 !important;
    }
  }
}

.rs-title {
  @include font(16px, bold, color-primary());

  margin-bottom: 12px;
  margin-top: 25px;
}

.rs-big-title {
  @include font(24px, bold, color-primary());
}

.rs-centered-title {
  text-align: center;
}

.rs-middle-title {
  @include font(16px, 500, color-primary());
}

.rs-title-info {
  @include font(16px, bold, color-primary());

  line-height: 1;
  margin-bottom: 12px;
  margin-top: 25px;
}

// Toggle button styles
.mat-button-toggle {
  color: $common-primary-color !important;
}

.mat-button-toggle-checked {
  background-color: $common-primary-color !important;
  color: $white !important;
  border-color: $common-primary-color !important;
}

// Styles for disabled buttons, because primary color overrides them
.mat-button-disabled {
  .mat-icon {
    color: $disabled-icon-button;
  }
}

@font-face {
  font-family: icomoon;
  src: url('./assets/fonts/icomoon.eot');
  src: url('./assets/fonts/icomoon.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/icomoon.woff') format('woff'),
    url('./assets/fonts/icomoon.ttf') format('truetype'),
    url('./assets/fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

.slick-arrow {
  display: none !important;
}

input:autofill,
input:-webkit-autofill-strong-password,
input:-webkit-autofill-strong-password-viewable,
input:-webkit-autofill-and-obscured,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  background-color: white !important;
  height: 22px;
}

.rs-go-red-link {
  &,
  &:hover {
    color: $new-secondary-color;
  }
}

.rs-base-container {
  max-width: 1400px;
  margin: 0 auto;
  height: 100%;
  width: 100%;
}

.rs-mobile-menu-asian {
  .mat-dialog-container {
    background-image: url('./assets/img/asian-template/asian-background.svg');
  }
}

a:hover {
  text-decoration: none !important;
}

.mat-tooltip.rs-checkout-tooltip {
  @media (max-width: 768px) or (min-width: 935px) {
    display: none;
  }
}

// make all dialogs scrollable in vertical direction when screen height is less then dialog content height
.cdk-overlay-pane {
  overflow-y: auto;
}

@media print {
  .rs-hidden-for-print {
    display: none !important;
  }

  * {
    print-color-adjust: exact;
  }

  img {
    display: list-item !important;
  }

  .d-table--print {
    display: table !important;
  }

  .d-table-cell--print {
    display: table-cell !important;
  }
}

.flex-1 {
  flex: 1;
}

.w-fit-content {
  width: fit-content;
}

.rs-sticky-dialog-header {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: inherit;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white !important;
}

.mat-button-toggle-group {
  flex-wrap: wrap;
  box-shadow: none;
  border: 0 !important;

  .mat-button-toggle-appearance-standard {
    border-radius: 20px;
    background-clip: padding-box;
  }
}

mat-button-toggle {
  &.mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
    height: 36px !important;
    border-radius: 64px;
    background: color-surface();
    box-shadow: none !important;

    &:hover {
      border: 1px solid color-outline() !important;
      background: color-surface_focus(12%) !important;
    }

    button.mat-button-toggle-button {
      border-radius: 20px !important;
      color: color-on_surface() !important;
      background-color: $white !important;
      border: 1px solid $input-border !important;
    }

    mat-icon {
      @include size(16px !important, 16px !important);
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.rs-default-gap {
  gap: spacing(1);
}

.rs-two-lines-text-ellipsis {
  @include text-ellipsis();
}

.rs-one-line-text-ellipsis {
  @include text-ellipsis(1);
}

* {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.rs-hide-on-scroll {
  &,
  &.d-block,
  &.d-flex {
    display: none !important;
  }
}

.rs-food-menu-search-rolled,
.rs-food-menu-search-rolled--before {
  transition: top 3s linear;
}

ul {
  list-style-type: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  background-color: color-on_primary();
}

@media (max-width: $rs-1-mobile-card-max-screen-width) {
  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-none {
    display: none !important;
  }
}

@media (min-width: $rs-1-card-min-screen-width) and (max-width: $rs-1-card-max-screen-width) {
  .d-md-flex {
    display: flex !important;
  }

  .d-md-none {
    display: none !important;
  }
}

@media (min-width: $rs-2-card-min-screen-width) and (max-width: $rs-2-card-max-screen-width) {
  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-none {
    display: none !important;
  }
}

@media (min-width: $rs-3-card-min-screen-width) {
  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-none {
    display: none !important;
  }
}

[dir='rtl'] {
  .ml-auto {
    margin-left: unset !important;
    margin-right: auto;
  }

  .mr-auto {
    margin-right: unset !important;
    margin-left: auto;
  }
}

.rs-invisible {
  &,
  &:hover,
  &:disabled,
  &:focus,
  &:active {
    &,
    & * {
      color: transparent !important;
      background-color: transparent !important;
    }

    mat-icon svg path {
      fill: transparent !important;
    }
  }
}

.rs-w-fit-content {
  width: fit-content;
}

.rs-crossed-out {
  text-decoration: line-through;
}
