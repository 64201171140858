@import 'src/app/_shared/_styles/mixins';
@import 'src/app/_shared/_styles/rs-variables/colors';
@import 'src/app/_shared/_styles/rs-variables/spacing';

.rs-card-container {
  border-radius: spacing(1);
  background-color: color-surface();
  display: flex;
  min-height: 60px;
  min-width: 60px;

  &--outline,
  &--elevate-outline {
    border: 1px solid color-outline();
  }

  &--elevate,
  &--elevate-outline {
    box-shadow: 0 1px 1px 0 color-elevation-1(20%);
  }

  &:hover:not(.rs-card-container--disabled, :disabled) {
    background-color: color-surface_focus(12%);
  }

  &:active:not(.rs-card-container--disabled, :disabled) {
    background-color: color-surface_focus(16%);
  }

  &--active:not(.rs-card-container--disabled, :disabled) {
    background-color: color-surface_select(12%);
  }

  &--disabled,
  &:disabled {
    background-color: color-surface_variant();
  }
}
