@import 'src/app/_shared/_styles/mixins';
@import 'src/app/_shared/_styles/rs-variables/colors';

.rs-chip-sticker {
  background-color: color-warning();
  color: color-on_warning() !important;
  padding: 4px 12px;
  cursor: pointer;

  mat-icon svg path {
    fill: color-on_warning() !important;
  }

  &__inner {
    flex: 1 0 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.08px;
    text-transform: uppercase;
  }

  &--pressed {
    background-color: color-warning(84%);
  }
}
