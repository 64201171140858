@import 'src/app/_shared/_styles/mixins';
@import 'src/app/_shared/_styles/rs-variables/colors';
@import 'src/app/_shared/_styles/rs-variables/spacing';

.rs-radio-button-box.mat-radio-button {
  width: 156px !important;

  .mat-radio-container {
    @include size(20px !important, 20px !important);

    display: inline-flex !important;
    align-self: flex-start !important;
  }

  .mat-radio-label {
    max-width: 132px !important;
    width: 132px !important;
    justify-content: space-between !important;

    ::ng-deep .mat-radio-label-content {
      max-width: 96px !important;
      width: 96px !important;
      padding: 0 !important;
      display: flex !important;
      flex-direction: column !important;
    }
  }

  .mat-radio-label-content {
    max-width: 96px !important;
    width: 96px !important;
    padding: 0 !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .mat-radio-ripple {
    display: none !important;
  }

  &.mat-radio-checked {
    &,
    & .mat-radio-container:hover {
      .mat-radio-outer-circle {
        &::before {
          font-size: 12px !important;
        }
      }
    }
  }
}

.rs-radio-button-box {
  padding: spacing(1.5);
  border-radius: spacing(1);
  border: none;
  outline: 1px solid color-outline() !important;

  &:not(.rs-radio-button-box--disabled) {
    &:hover {
      background: color-surface_focus(12%) !important;
    }

    &:focus {
      outline: 4px solid color-outline_focus() !important;
    }
  }

  &--selected:not(.rs-radio-button-box--disabled) {
    outline: 4px solid color-on_surface() !important;
    background-color: color-surface_select(8%);
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.38;
  }

  &__label,
  &__supporting {
    font-style: normal;
    margin: 0;
    display: inline-block !important;
  }

  &__label {
    @include font(14px, 700, color-on_surface());

    letter-spacing: 0.07px;
    line-height: 20px;

    &:empty {
      display: none;
    }
  }

  &__supporting {
    @include font(16px, 700, color-on_surface());

    letter-spacing: 0.032em;
    line-height: 24px;
  }
}

.rs-radio-button-box-group {
  gap: 16px;

  @media (max-width: 576px) {
    padding: 0 4px;
  }
}
