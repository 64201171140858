$primary-color: #00a7ef;
$primary: #00a7ef;
$primary-bg: #a8e0f7;
$active-primary-color: #0090ce;
$light-bg: #f1f5f7;
$secondary-color: #ddd;
$red-color: #fd3838;
$dark-red: #f05252; // color-error()
$light-red: #fee7e7; // color-error(16%)
$lighter-red-color: #ff3877;
$text-color: #454545;
$grey-color: #999; // color-outline()
$darker-grey-color: #7d7d7d;
$white: #fff; // color-surface()
$green: #0be377;
$orange: #fa6400;
$orange-bg: #f9c19b;
$diet: #7c3200;

$light-border-vReports: #cdcdcd;

$grey-background-color: #f9f9f9;
$lighter-grey-background-color: #f7f7f7; // color-on_surface_select() or color-primary_container()
$light-grey-background: #f3f5f7;
$grey-hints-text: #4a4a4a;
$grey-mat-icon-button: #7d7a77;
$grey-progressbar-background: #f6f6f6;
$line: #d8d8d8;
$border-lightgrey-line: #d7d7d7;
$checkbox-border: #5a5a5a;
$password-strength: #7d7a77;
$moderate-password: #f7b500;
$strong-password: #f2f54e;
$complete-password: #62dd51;
$warn: #fd3838;
$inactive-icon: #757575;
$title-text: #3a3a3a;
$hint: #808080;
$warning-hint: #f7b500;
$icon-button-2: #979797;
$disabled-icon-button: #ccc;
$input-grey-line: #cdcdcd; // color-outline()
$input-line: #dfe2e4;
$input-border: #cdcdcd; // color-outline()
$shadow: rgb(0 0 0 / 54%); // color-outline()
$shadow-grey: rgb(255 255 255 / 70%);
$input-text: rgb(0 0 0 / 87%);
$rating-background: rgb(247 247 247 / 47%);
$icon-button: #333; // color-primary()
$flat-button-background: #333; // color-primary()
$default-user-photo: #757575;
$table-background: #f6f6f6;
$table-border: #ddd;
$dark-table-border: #999;
$shadow-color: rgb(0 0 0 / 15%);
$button: #9b9b9b;
$bg-text: #828282;
$chip-bg: #a9a6a6;
$input-text: #4b4b4b;
$counter-action: #f7a6a8;
$popup-footer-shadow: #e1e1e1;
$popup-footer-background: #f7f7f7;
$button-icon: #f7a6a8;
$dark-cyan: #6293aa;
$new-primary-color: var(--primary);
$new-secondary-color: var(--secondary);
$common-primary-color: var(--common-primary);
$common-secondary-color: var(--common-secondary);

$light-blue: #f3f8fa;
$dark-grey-line: #9b9b9b;
$text: #333; // color-primary()
$powered-by-text: #332927;
$grey-border: #979797;
$light-grey-border: #ccc;
$gift-card-subtext: #7d7a77;
$gift-card-divider: #f7f7f7;
$modifier-required: #f13561;

$soon-available-menu: #fee7e7;

$mat-card-background: #efefef;
$disabled-mat-card-background: #f7f7f7;
$unavailable-mat-card: #f7f7f780;

$weak-password-strength: #fb1d09;
$moderate-password-strength: #f7b500;
$strong-password-strength: #6dd400;
$very-strong-password-strength: #00a7ef;

$passed-password-check: #92c16f;
$green-text: #92c16f; // color-information()
$red-text: #fb1d09; // color-error()
$lightgrey-text: #9b9b9b; // color-outline_variant()
$orange-text: #ed4a00;
$light-green-background: rgb(146 193 111 / 10%);

$white-text: #fff; // color-on_primary()

$disabled-button: #cdcdcd;
$checked-toggle-background: #333; // color-primary()
$toggle-border: #ddd;

$exclamation-triangle: #c0eaff;
$red: #f00;
$black: #000;
$neutral-blue: #ecf9ff;

$light-grey-fade: #969696;
$light-grey-field-border: #0000001f;
$light-grey-background: #f7f8f8;
$classic-template-background-color: #f7f8f8;

$dark: #1f1f1f;
$dark-mode-bg: #71643f;
$gold: #e7ba66;
$asian: #00121a;

$selected-blue-border: #0028d7; // color-outline_focus()
$selected-blue-shadow: #3f62ff;

$vertical-stepper-green: #06775d;
$vertical-stepper-orange: #fa6400;
$vertical-stepper-red: #fd3838;

$input-error-background: #fee7e7;
$input-error-border: #f05252;
$input-focused-border: #0028d7;
$input-active-border: #979797;
$input-idle-border: #cdcdcd;
$input-placeholder-color: #6e6e73;

$info-notification-background: #ffdac1;
$info-notification-icon: #ee6300;

$dine-in-background-color: #e6f1f6;
$dine-in-tip-selected-border: #007ac7;
$welcome-screen-input-border: #86868b;

$dine-in-warning-background: #ffe4d1;
$dine-in-warning-text: #bd4e00;
$grey-dash-divider: #bebebe;

$digital-storefront-allergens-selected-icon-color: #1e1e1e;
$digital-storefront-allergens-default-icon-color: #615f5f;

$disabled-icon: #d9d9d9;
